<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[loader] -->
    <ALoader :isLoading="api.isLoading"></ALoader>
    <!-- EOC -->
    <!-- BOC:[content] -->
    <div v-if="me.id">
      <!-- BOC:[header] -->
      <v-row class="mb-3">
        <v-col class="col-12 col-sm-6"
          ><h1>{{ me.name ? me.name : $t("model.name." + this.model.name.singular.toLowerCase()) }}</h1></v-col
        >
        <v-col class="col-12 col-sm-6 text-right">
          <BreadReadButtonDelete
            v-if="model[role].delete"
            class="ml-2"
            :me="me"
            :role="role"
            :model="model"
          ></BreadReadButtonDelete>
          <BreadReadButtonEdit
            v-if="model[role].edit"
            class="ml-2"
            :me="me"
            :role="role"
            :model="model"
          ></BreadReadButtonEdit>
        </v-col>
      </v-row>
      <!-- EOC -->
      <!-- BOC:[tabs] -->
      <v-tabs
        v-model="tab"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
      >
        <v-tab
          v-for="item in model[role].read.tabs"
          :key="item.key"
          :href="`#${item.key}`"
          @click="updateTab(item.key)"
        >
          {{ item.label }}
        </v-tab>
      </v-tabs>
      <!-- EOC -->
      <!-- BOC:[tab] -->
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in model[role].read.tabs"
          class="px-1 py-2"
          :value="item.key"
          :key="item.key"
        >
          <BreadReadInfo
            v-if="item.key == 'Info'"
            :role="role"
            :model="model"
            :data="me"
             @refetch="refetchInfo"
          ></BreadReadInfo>
          <component
            v-else
            :is="'Tab' + role + model.key + item.key"
            :role="role"
            :parent="me"
          ></component>
        </v-tab-item>
      </v-tabs-items>
      <!-- EOC -->
    </div>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/festival";
//EOC
import BreadReadButtonDelete from "@/components/Bread/BreadReadButtonDelete";
import BreadReadButtonEdit from "@/components/Bread/BreadReadButtonEdit";
import BreadReadInfo from "@/components/Bread/BreadReadInfo";
import TabModeratorFestivalFestivalSchool from "@/tabs/TabModeratorFestivalFestivalSchool";
import TabModeratorFestivalFestivalPrize from "@/tabs/TabModeratorFestivalFestivalPrize";
import TabModeratorFestivalFestivalSponsor from "@/tabs/TabModeratorFestivalFestivalSponsor";
import TabModeratorFestivalEventOnFestival from "@/tabs/TabModeratorFestivalEventOnFestival";

import { mapState } from "vuex";
export default {
  components: {
    BreadReadButtonDelete,
    BreadReadButtonEdit,
    BreadReadInfo,
    TabModeratorFestivalFestivalPrize,
    TabModeratorFestivalFestivalSchool,
    TabModeratorFestivalFestivalSponsor,
    TabModeratorFestivalEventOnFestival,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["self"],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[tabs]
    tab: null,
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[self]
    me: {
      id: null,
    },
    //EOC
  }),
  created() {
    //BOC:[guard]
    if (!this.model[this.role].read) {
      this.$router.replace(this.$_getRouteBrowse(this.role, this.model.key));
      return;
    }
    //EOC
    //BOC:[self]
    if (this.self) this.me = this.$_.cloneDeep(this.self);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.me.name
        ? this.me.name
        : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${this.$route.params.id}`,
      to: this.$_getRouteRead(
        this.role,
        this.model.key,
        this.$route.params.id,
        this.self
      ),
      exact: true,
    });
    //EOC
    //BOC:[tabs]
    if (this.$route.query.tab) this.tab = this.$route.query.tab;
    //EOC
    //BOC:[url]
    this.api.url = `${this.$api.servers.moderator}/v1/school/${this.school.id}/festival/${this.$route.params.id}`;
    //EOC
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.me = resp;
      //BOC:[breadcrumbs]
      if (resp.name)
        this.breadcrumbs[this.breadcrumbs.length - 1].text = resp.name;
      //EOC
    };
    //EOC
  },
  mounted() {
    //BOC:[self]
    if (!this.me.id) this.$api.fetch(this.api);
    //EOC
  },
  methods: {
    refetchInfo(){
this.$api.fetch(this.api);
    },
    //
    updateTab(tab) {
      window.history.pushState('','','?tab='+ tab);
    },
  },
  beforeMount() {
    this.$api.fetch(this.api);
  },
};
</script>